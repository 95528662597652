<template>
  <div class="template icon_error">
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.5 38C29.9934 38 38.5 29.4934 38.5 19C38.5 8.50659 29.9934 0 19.5 0C9.00659 0 0.5 8.50659 0.5 19C0.5 29.4934 9.00659 38 19.5 38ZM14.2032 11.2598L11.7588 13.7042L17.0551 19.0005L11.7588 24.2968L14.2032 26.7412L19.4995 21.445L24.7958 26.7412L27.2403 24.2968L21.944 19.0005L27.2403 13.7042L24.7958 11.2598L19.4995 16.5561L14.2032 11.2598Z"
        fill="#F93A20"
      />
    </svg>
  </div>
</template>
<style lang="scss" scoped>
template.icon_error {
  width: 0.39rem;
  height: 0.39rem;
  svg {
    width: 100%;
    height: 100%;
  }
}
</style>
