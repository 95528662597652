<template>
  <div class="template icon_right">
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38ZM9 18.3L11.0992 16.1764L16.7753 21.739L26.8571 12L29 14.1L16.8571 26L9 18.3Z"
        fill="#37AB00"
      />
    </svg>
  </div>
</template>
<style lang="scss" scoped>
template.icon_right {
  width: 0.39rem;
  height: 0.39rem;
  svg {
    width: 100%;
    height: 100%;
  }
}
</style>
