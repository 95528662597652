<style lang="scss" scoped>
.main_left {
  width: 7.57rem;
  border-right: 1px solid #38366c;
  flex-grow: 1;
  .qrc {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .code {
      border: 0.2rem solid #fff;
      border-radius: 0.1rem;
      canvas {
        width: 2.3rem !important;
        height: 2.3rem !important;
      }
      &.noborder {
        border: 0;
      }
    }
  }
  p {
    font-size: 0.24rem;
    &.tips {
      font-size: 0.14rem;
    }
  }
}
.main_right {
  width: 9.35rem;
  h2 {
    padding-left: 0.3rem;
    border-bottom: 1px solid #38366c;
    height: 0.6rem;
    margin: 0;
    line-height: 0.6rem;
    font-weight: normal;
    font-size: 0.18rem;
  }
  ul {
    width: 5rem;
    padding: 0.3rem;
    li {
      padding: 0.12rem 0;
      font-size: 0.16rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .label {
        opacity: 0.8;
      }
      .val {
        display: flex;
        align-items: center;
        svg {
          width: 0.24rem;
          height: 0.24rem;
          margin-left: 0.1rem;
        }
      }
      .copy {
        padding: 0.08rem 0.12rem;
        background: rgba(239, 79, 85, 0.1);
        font-size: 0.18rem;
        cursor: pointer;
      }
    }
  }
}
</style>
<template>
  <div class="main_left">
    <slot></slot>
    <template v-if="routerQurey.type == 'ybf'">
      <div class="qrc" v-if="!props.modelValue">
        {{ lang == "en" ? "等待用戶轉賬" : "Expecting Transfer" }}
      </div>
      <div class="qrc" v-else-if="props.modelValue.order_status == 1">
        <div class="code noborder">
          <icon_right />
        </div>
        <p>{{ lang == "en" ? "SUCCESS" : "充值成功" }}</p>
      </div>
      <div class="qrc" v-else-if="props.modelValue.order_status == 2">
        <p>{{ lang == "en" ? "UNDERWAY" : "充值進行中" }}</p>
      </div>
      <div class="qrc" v-else-if="props.modelValue.order_status == 3">
        <div class="code noborder">
          <icon_error />
        </div>
        <p>{{ lang == "en" ? "FAILED" : "充值失敗" }}</p>
      </div>
    </template>
    <template v-else-if="routerQurey.type == 'excharge'">
      <div class="qrc" v-if="props.modelValue.status == 0">
        <div class="code">
          <QrcodeVue :value="qrcData" :size="QRCsize" level="H" />
        </div>
        <p>{{ lang == "en" ? "Wallet Pay QR Code" : "錢包付款二維碼" }}</p>
        <p class="tips">
          <span>*</span>
          {{
            lang == "en"
              ? "Reminder: Please make a transfer to the listed address within 30 minutes.Attempts after that time period will not be accepted. Transfer amount must match designated amount or despoit cannot be confirmed."
              : "提示：請在30分鐘內轉賬到指定錢包地址，超過時間後請勿再轉賬，否則無法到賬。指定金額必須一致，否者無法確認金額。"
          }}
        </p>
      </div>
      <div class="qrc" v-else-if="props.modelValue.status == 1">
        <div class="code noborder">
          <icon_right />
        </div>
        <p>{{ formatChainStatus(props.modelValue.status) }}</p>
      </div>
      <div class="qrc" v-else-if="props.modelValue.status == 2">
        <div class="code noborder">
          <icon_error />
        </div>
        <p>{{ formatChainStatus(props.modelValue.status) }}</p>
        <p class="tips">
          <span>*</span>
          {{
            lang == "en"
              ? "Reminder: This transaction has expired. Any amount further transferred cannot be confirmed."
              : "提示：該訂單已經超時，請勿再轉賬，否者金額無法確認。"
          }}
        </p>
      </div>
      <div class="qrc" v-else-if="props.modelValue.status == 3">
        <p>{{ formatChainStatus(props.modelValue.status) }}</p>
        <p class="tips">
          <span>*</span>
          {{
            lang == "en"
              ? "Reminder: Withdraw may take longer than expected due to network delays."
              : "提示：提現會存在延時到賬情況，需根據鏈路情況決定。"
          }}
        </p>
        <p class="tips">
          <span>*</span>
          {{
            lang == "en"
              ? "Please note that only decentralised wallets are supported for trading operations, using exchange wallets (Coin, Fire, Ouyi, pexpay) may lead to unforeseen consequences <we recommend using decentralised wallets such as metamask, TokenPocket, imToken, etc. for operations>."
              : "請注意只支持使用去中心化錢包進行交易操作 ，如使用交易所錢包（幣安、火幣、歐易、pexpay），將可能導致不可預期的後果<建議使用metamask、TokenPocket、imToken等去中心化錢包進行操作>"
          }}
        </p>
      </div>
    </template>
    <template  v-else-if="routerQurey.type == 'withdraw'">
      <div class="qrc" v-if="props.modelValue.status == 0">
        <p>{{ lang == "en" ? "Transferring" : "處理中" }}</p>
        <p class="tips">
          <span>*</span>
          {{
            lang == "en"
              ? "Reminder: Withdraw may take longer than expected due to network delays."
              : "提示：提現會存在延時到賬情況，需根據鏈路情況決定。"
          }}
        </p>
        <p class="tips">
          <span>*</span>
          {{
            lang == "en"
              ? "Please note that only decentralised wallets are supported for trading operations, using exchange wallets (Coin, Fire, Ouyi, pexpay) may lead to unforeseen consequences <we recommend using decentralised wallets such as metamask, TokenPocket, imToken, etc. for operations>."
              : "請注意只支持使用去中心化錢包進行交易操作 ，如使用交易所錢包（幣安、火幣、歐易、pexpay），將可能導致不可預期的後果<建議使用metamask、TokenPocket、imToken等去中心化錢包進行操作>"
          }}
        </p>
      </div>
      <div class="qrc" v-else-if="props.modelValue.status == 1">
        <div class="code noborder">
          <icon_right />
        </div>
        <p>{{ formatChainStatus(props.modelValue.status) }}</p>
        <p class="tips">
          <span>*</span>
          {{
            lang == "en"
              ? "Reminder: Withdraw may take longer than expected due to network delays."
              : "提示：提現會存在延時到賬情況，需根據鏈路情況決定。"
          }}
        </p>
        <p class="tips">
          <span>*</span>
          {{
            lang == "en"
              ? "Please note that only decentralised wallets are supported for trading operations, using exchange wallets (Coin, Fire, Ouyi, pexpay) may lead to unforeseen consequences <we recommend using decentralised wallets such as metamask, TokenPocket, imToken, etc. for operations>."
              : "請注意只支持使用去中心化錢包進行交易操作 ，如使用交易所錢包（幣安、火幣、歐易、pexpay），將可能導致不可預期的後果<建議使用metamask、TokenPocket、imToken等去中心化錢包進行操作>"
          }}
        </p>
      </div>
      <div class="qrc" v-else-if="props.modelValue.status == 2">
        <div class="code noborder">
          <icon_error />
        </div>
        <p>{{ lang == "en" ? "Abnormal" : "異常" }}</p>
        <UiButton
          @click="showFeebackTag = true"
          width="0.66rem"
          height="0.33rem"
          fontsize="0.18rem"
          lineHeight="0.33rem"
          >{{ lang == "en" ? "Arbitration" : "仲裁" }}</UiButton
        >
        <p class="tips">
          <span>*</span>
          {{
            lang == "en"
              ? "Reminder: This transaction has expired. Any amount further transferred cannot be confirmed."
              : "提示：該訂單已經超時，請勿再轉賬，否者金額無法確認。"
          }}
        </p>
      </div>
      <div class="qrc" v-else-if="props.modelValue.status == 3">
        <p>{{ formatChainStatus(props.modelValue.status) }}</p>
        <p class="tips">
          <span>*</span>
          {{
            lang == "en"
              ? "Reminder: Withdraw may take longer than expected due to network delays."
              : "提示：提現會存在延時到賬情況，需根據鏈路情況決定!"
          }}
        </p>
      </div>
    </template>
  </div>
  <div class="main_right" v-if="routerQurey.type == 'ybf' && props.modelValue">
    <h2 class="onlypc">{{ lang == "en" ? "Transaction Detail" : "訂單詳情" }}</h2>
    <ul>
      <li>
        <span class="label">{{ lang == "en" ? "Submission Time" : "提交時間" }}</span>
        <span class="val">{{ common.formatDateTime(props.modelValue.add_time) }}</span>
      </li>
      <li>
        <span class="label">{{ lang == "en" ? "Amount" : "金額" }}</span>
        <span class="val">{{ props.modelValue.asset_count }}</span>
      </li>
      <li>
        <span class="label">{{ lang == "en" ? "Fee" : "手續費" }}</span>
        <span class="val">{{ props.modelValue.charge }}</span>
      </li>
    </ul>
  </div>
  <div class="main_right" v-else-if="props.modelValue">
    <h2 class="onlypc">{{ lang == "en" ? "Transaction Detail" : "訂單詳情" }}</h2>
    <ul :data-type="routerQurey.type">
      <li>
        <span class="label">{{ lang == "en" ? "Currency" : "區塊鏈類型" }}</span
        ><span class="val">{{ common.chain_tp[props.modelValue.chain_tp] }}</span>
      </li>
      <li>
        <span class="label">{{ lang == "en" ? "Network" : "鏈幣支付類型" }}</span
        ><span class="val">{{ props.modelValue.chain_payment_name }}</span>
      </li>
      <li v-if="routerQurey.type == 'withdraw'">
        <span class="label">{{ lang == "en" ? "Fee" : "手續費" }}</span>
        <span class="val">{{ props.modelValue.charge / 1000 }} <Icon_u /></span>
      </li>
      <li class="hr" v-show="props.modelValue.status != 2"></li>
      <li v-if="props.modelValue.status != 2">
        <span class="label">{{ lang == "en" ? "Address" : "錢包地址" }}</span
        ><span class="val red copy" @click="copyAddress">{{
          lang == "en" ? "COPY" : "複製"
        }}</span>
      </li>
      <li v-if="props.modelValue.status != 2 && routerQurey.type == 'excharge'">
        <span class="address">{{ props.modelValue.systemAccount || systemAccount }}</span>
      </li>
      <li v-if="props.modelValue.status != 2 && routerQurey.type == 'withdraw'">
        <span class="address">{{ props.modelValue.chain_account }}</span>
      </li>
      <li class="hr"></li>
      <li>
        <span class="label">{{ lang == "en" ? "Submission Time" : "提交時間" }}</span
        ><span class="val">{{ common.formatDateTime(props.modelValue.add_time) }}</span>
      </li>
      <li>
        <span class="label">{{ lang == "en" ? "Status" : "狀態" }}</span
        ><span
          :class="{
            val: true,
            color: true,
            orange: props.modelValue.status == 0,
            green: props.modelValue.status == 1,
            red: props.modelValue.status == 2,
          }"
          >{{ formatChainStatus(props.modelValue.status) }}</span
        >
      </li>
      <li v-if="props.modelValue.status == 1" class="hr"></li>
      <li v-if="props.modelValue.status == 1">
        <span class="label">{{ lang == "en" ? "Action" : "操作" }}</span>
        <UiButton
          @click="showFeebackTag = true"
          width="0.66rem"
          height="0.33rem"
          fontsize="0.18rem"
          lineHeight="0.33rem"
          >{{ lang == "en" ? "Arbitration" : "仲裁" }}</UiButton
        >
      </li>
    </ul>
  </div>
</template>
<script setup>
import { ref, inject, computed, watch, onMounted } from "vue";
import QrcodeVue from "qrcode.vue";
import icon_right from "@/components/ui/icon/icon_right.vue";
import icon_error from "@/components/ui/icon/icon_error.vue";
import common from "../../utils/common.js";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import UiButton from "../ui/ui_btn_2.vue";
import axios from "axios";
import Icon_u from "../ui/icon/icon_u.vue";

let router = useRouter();
let store = useStore();
let lang = computed(() => {
  return store.state.lang;
});
let routerQurey = computed(() => {
  return router.currentRoute.value.query;
});

let props = defineProps(["modelValue"]);

let formatChainStatus = inject("formatChainStatus");

// 充值二維碼
let QRCsize = ref(256);
let qrcData = ref();

// 系統錢包地址，充值時用
let systemAccount = ref("_");
// 充值和提現的渠道信息
const channel_infos = ref();
const getChannelInfos = () => {
  const sendUrl = common.userApi.requestUrl + "/finance_api/bwallet/channel_infos";
  const sendHeader = common.buildHeaders("form");
  axios.get(sendUrl, { headers: sendHeader }).then((r) => {
    if (r.data.code == 0) {
      channel_infos.value = r.data.infos;
      if (channel_infos.value && channel_infos.value.length > 0) {
        for (let i = 0; i < channel_infos.value.length; i++) {
          if (props.modelValue.chain_tp == channel_infos.value[i].chain_tp) {
            for (let j = 0; j < channel_infos.value[i].chain_currency_infos.length; j++) {
              if (
                props.modelValue.chain_currency ==
                channel_infos.value[i].chain_currency_infos[j].currency
              ) {
                systemAccount.value =
                  channel_infos.value[i].chain_currency_infos[j].system_account;
                if (routerQurey.value.type == "excharge") {
                  qrcData.value = props.modelValue.systemAccount || systemAccount.value;
                  console.log(
                    "qrcData.value",
                    qrcData.value,
                    props.modelValue.systemAccount,
                    systemAccount.value,
                    336
                  );
                }
                return;
              }
            }
          }
        }
      }
    }
  });
};
if (routerQurey.value.type == "excharge") {
  getChannelInfos();
}

// 複製
const copyAddress = async () => {
  await common.toClipboard(qrcData.value);
  toastr["success"]("Address copied successfully, go paste it !!");
};

onMounted(() => {});
</script>
