<template>
    <svg class="icon_u" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M44.9952 22.9418C44.9984 22.7949 45 22.6476 45 22.5C45 11.1782 35.598 2 24 2C12.402 2 3 11.1782 3 22.5C3 22.6476 3.0016 22.7949 3.00478 22.9418L3 26.0244H3.00624C3.00223 26.1825 3 26.3409 3 26.5C3 37.2695 12.4019 46 24 46C35.5981 46 45 37.2695 45 26.5C45 26.3409 44.9978 26.1825 44.9938 26.0244H45L44.9952 22.9418Z"
            fill="#71C6C6" />
        <path
            d="M24 46C35.5981 46 45 37.2695 45 26.5C45 26.3409 44.9978 26.1825 44.9938 26.0244H45L44.9938 22L44.2354 21.2683C41.7731 13.0394 33.6463 7 24 7C14.3537 7 6.22686 13.0394 3.76458 21.2683L3.00624 22L3 26.0244H3.00624C3.00223 26.1825 3 26.3409 3 26.5C3 37.2695 12.4019 46 24 46Z"
            fill="#1C9090" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M35.0001 9.88417C31.801 8.0539 28.0333 6.99805 24 6.99805C19.9667 6.99805 16.199 8.05388 13 9.88413V43.1121C16.1991 44.9422 19.9667 45.998 24 45.998C28.0333 45.998 31.801 44.9422 35.0001 43.112V9.88417Z"
            fill="#1B7E7E" />
        <ellipse cx="24" cy="22.5" rx="21" ry="20.5" fill="#71C6C6" />
        <path
            d="M38.8492 8.00431C36.8992 6.10071 34.5842 4.59069 32.0363 3.56047C29.4885 2.53025 26.7578 2 24 2C21.2422 2 18.5115 2.53025 15.9636 3.56047C13.4158 4.59069 11.1008 6.10071 9.15075 8.00432L24 22.5L38.8492 8.00431Z"
            fill="#84E4E4" />
        <path d="M29.4352 2.69852C25.8745 1.76716 22.1255 1.76716 18.5648 2.69852L24 22.5L29.4352 2.69852Z"
            fill="#C5FFFF" />
        <path
            d="M5.81347 32.75C7.6566 35.8664 10.3076 38.4543 13.5 40.2535C16.6924 42.0528 20.3137 43 24 43C27.6863 43 31.3076 42.0528 34.5 40.2535C37.6924 38.4543 40.3434 35.8664 42.1865 32.75L24 22.5L5.81347 32.75Z"
            fill="#36B0B0" />
        <path
            d="M13.5 40.2535C16.6924 42.0528 20.3137 43 24 43C27.6863 43 31.3076 42.0528 34.5 40.2535L24 22.5L13.5 40.2535Z"
            fill="#229898" />
        <ellipse cx="24" cy="22.002" rx="17" ry="16" fill="url(#paint0_linear_2007_129521)"
            stroke="url(#paint1_linear_2007_129521)" />
        <ellipse cx="24" cy="22.5" rx="15" ry="14.5" fill="#2DB0B0" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M19 15.0039H16V25.0039C16 29.4222 19.5817 33.0039 24 33.0039C28.4183 33.0039 32 29.4222 32 25.0039V15.0039H29V24.3849C29 27.1463 26.7615 29.3849 24 29.3849C21.2386 29.3849 19 27.1463 19 24.3849V15.0039Z"
            fill="#50CDCD" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M19 14H16V23C16 27.4183 19.5817 31 24 31C28.4183 31 32 27.4183 32 23V14H29V23C29 25.7614 26.7615 28 24 28C21.2386 28 19 25.7614 19 23V14Z"
            fill="url(#paint2_linear_2007_129521)" />
        <defs>
            <linearGradient id="paint0_linear_2007_129521" x1="24" y1="6.00195" x2="24" y2="38.002"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#105B5B" />
                <stop offset="1" stop-color="#26B8B8" />
            </linearGradient>
            <linearGradient id="paint1_linear_2007_129521" x1="24" y1="6.00195" x2="24" y2="38.002"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#36B0B0" />
                <stop offset="1" stop-color="#D7FFFF" />
            </linearGradient>
            <linearGradient id="paint2_linear_2007_129521" x1="24" y1="13" x2="24" y2="31" gradientUnits="userSpaceOnUse">
                <stop offset="0.0075208" stop-color="#D2FFFF" />
                <stop offset="0.0076208" stop-color="#D4FFFF" />
                <stop offset="0.427083" stop-color="#DBFFFF" />
                <stop offset="1" stop-color="#B5FFFF" />
            </linearGradient>
        </defs>
    </svg>
</template>