<template>
  <div class="template detail udetail_v2" id="udetail_v2">
    <div class="v2box">
      <div class="caption">
        <div class="block_layout">
          <span class="back" @click="toU">
            <IconBack /><span class="onlypc">{{
              lang == "en" ? "Go Back" : "返 回"
            }}</span> </span
          ><span class="title">{{
            lang == "en" ? "Transaction Detail" : "訂單詳情"
          }}</span>
        </div>
      </div>
      <div class="container">
        <!-- <div class="detail_title onlypc" v-if="routerQurey.type == 'ybf'">
          <span>{{ lang == "en" ? "Gold Amount" : "金幣餘額" }}</span
          >{{ userG }}
        </div>
        <div v-else class="detail_title onlypc">
          <span>{{ lang == "en" ? "U Amount" : "U幣餘額" }}</span
          >{{ userU }}
        </div> -->

        <div class="block_layout">
          <div
            class="main"
            v-if="detailItem || (routerQurey.type == 'ybf' && routerQurey.order_id)"
          >
            <detailV2Main v-model="detailItem">
              <div class="fin ybf_fin" v-if="routerQurey.type == 'ybf'">
                <span>{{ lang == "en" ? "Amount" : "充值金額" }}</span>
                <div>
                  {{ detailItem ? detailItem.asset_count - detailItem.charge : "-" }}
                  <Icon_g class="icon" />
                </div>
              </div>
              <div class="fin" v-else>
                <span v-if="routerQurey.type == 'excharge'">{{
                  lang == "en" ? "Amount" : "充值金額"
                }}</span>
                <span v-else-if="routerQurey.type == 'withdraw'">{{
                  lang == "en" ? "Amount" : "提現金額"
                }}</span>
                <div v-if="routerQurey.type == 'excharge'">
                  {{ detailItem ? detailItem.short_count : "-" }}<Icon_u class="icon" />
                </div>
                <div v-else-if="routerQurey.type == 'withdraw'">
                  {{ detailItem ? detailItem.asset_count / 1000 : "-"
                  }}<Icon_u class="icon" />
                </div>
              </div>
            </detailV2Main>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import common from "../../utils/common";
import IconBack from "../ui/icon/icon_back.vue";
import { ref, inject, computed, watch, onMounted, onUnmounted } from "vue";
import IconClose from "../ui/icon/icon_close.vue";
import Icon_u from "../ui/icon/icon_u.vue";
import Icon_g from "../ui/icon/icon_gold.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import axios from "axios";
import detailV2Main from "./Detail_v2_main.vue";

let store = useStore();
let router = useRouter();
let lang = computed(() => {
  return store.state.lang;
});

let userInfo = computed(() => {
  return store.state.userInfo;
});

// 刷新餘額
let userU = ref();
let userG = ref();
const queryFinance = () => {
  common.queryFinance().then((r) => {
    if (r.data.code == 0 && r.data.infos.length >= 0) {
      const userFinanceInfo = r.data.infos[0][userInfo.value.uid];
      if (userFinanceInfo) {
        userU.value =
          common.getValueByFinanceType(common.financeType.diamond, userFinanceInfo) /
          1000;
        userG.value = common.getValueByFinanceType(
          common.financeType.gold,
          userFinanceInfo
        );
      }
    }
  });
};

let detailItem = ref({
  asset_count: 0,
  systemAccount: "",
});
detailItem.value = localStorage.getItem("uDetailItem")
  ? JSON.parse(localStorage.getItem("uDetailItem"))
  : null;
console.log("detailItem", detailItem.value);

let routerQurey = computed(() => {
  return router.currentRoute.value.query;
});
console.log("routerQurey", routerQurey.value);

const toU = () => {
  router.push("/u?activeindex=0");
};

let getData_SI;
// 根據訂單號獲取易幣付訂單信息
const getYBF_detail = (orderID) => {
  common.showLoading("getYBF_detail");
  localStorage.removeItem("uDetailItem");
  const sendUrl =
    common.userApi.requestUrl +
    "/finance_api/ebpay/deposit_orders?deposit_order_id=" +
    orderID;
  axios
    .get(sendUrl)
    .then((r) => {
      if (r.data.code == 0) {
        // 如果有訂單信息
        if (r.data.infos.length > 0) {
          detailItem.value = r.data.infos[0];
          console.log("detailItem", detailItem.value);
        }
        // 如果易幣付方沒有返回訂單信息，輪詢
        else {
          // if (!common.isProduction) {
          //   detailItem.value = {
          //     add_time: 1684344589,
          //     asset_count: 10000,
          //     asset_tp: 2,
          //     charge: 385,
          //     deposit_order_id: "ed0bb688-5dc6-4699-b16f-eb3acda0f73b",
          //     modify_time: 1684344589,
          //     order_status: 1,
          //     request_amount: "10.0000000000000000",
          //     request_currency: "1",
          //     settlement_amount: "10.000000000000000000",
          //     settlement_asset_type: "1",
          //     transaction_id: "1658767123209576448",
          //     uid: "50571064",
          //   };
          //   console.log("detailItem", detailItem.value);
          //   return;
          // }
          getData_SI = setTimeout(() => {
            getYBF_detail(routerQurey.value.order_id);
          }, 30000);
        }
      }
    })
    .finally(() => {
      common.hideLoading("getYBF_detail");
    });
};
//獲取第一個交易信息
const getFirstChainOrder = (orderType) => {
  if (routerQurey.value.first == "true" && orderType) {
    console.log("getFirstChainOrder" + ":orderType");
    common.showLoading("getFirstChainOrder");
    axios
      .get(
        common.userApi.requestUrl +
          "/finance_api/bwallet/" +
          orderType +
          "_orders?status=0&offset=0&count=1"
      )
      .then((r) => {
        if (r.data.code == 0) {
          if (r.data.infos.length > 0) {
            detailItem.value = r.data.infos[0];
            console.log("detailItem", detailItem.value);
            if (detailItem.value.status == 0) {
              getData_SI = setTimeout(() => {
                getFirstChainOrder(orderType);
              }, 30000);
            }
          }
        }
      })
      .finally(() => {
        common.hideLoading("getFirstChainOrder");
      });
  }
};

onMounted(() => {
  queryFinance();
  if (routerQurey.value && routerQurey.value.type == "ybf") {
    if (routerQurey.value.order_id) {
      getYBF_detail(routerQurey.value.order_id);
    } else {
      toastr["error"]("Error order_id");
    }
  }
  // 從U幣充值或U幣提現頁跳轉來
  console.log("detailItem.value", detailItem.value);
  if (routerQurey.value.first == "true") {
    const orderType = routerQurey.value.type;
    getFirstChainOrder(orderType);
  }
  //否則從別處來，有詳情數據
  else if (detailItem.value.status == 0) {
    getFirstChainOrder(routerQurey.value.type);
  }
});
onUnmounted(() => {
  clearTimeout(getData_SI);
});
</script>
<style lang="scss" scoped>
#udetail_v2.udetail_v2 {
  background: #161a32;
  position: fixed;
  left: 0;
  top: var(--navHeight);
  height: calc(100vh - var(--navHeight));
  box-sizing: border-box;
  width: 100%;
  .v2box {
    height: 100%;
    overflow-y: auto;
    .block_layout {
      max-width: 14.4rem;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .caption {
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%),
      #1b203f;
    .block_layout {
      display: flex;
      .title {
        width: 100%;
        text-align: center;
      }
      .back {
        flex-shrink: 0;
        flex-grow: 0;
      }
    }
    .back {
      opacity: 0.5;
      span {
        font-size: 0.18rem;
      }
    }
  }
  .container {
    margin: 0 0.24rem;
    height: calc(100vh - var(--navHeight) - 0.78rem - 0.11rem);
    color: #fff;
    .detail_title {
      font-size: 0.24rem;
      border: 1px solid #38366c;
      padding-left: 0.3rem;
      height: 1.23rem;
      display: flex;
      align-items: center;
      span {
        opacity: 0.8;
        font-size: 0.18rem;
        margin-right: 0.1rem;
      }
    }
    .fin {
      height: 1.28rem;
      padding: 0.16rem 0.23rem;
      box-sizing: border-box;
      background: rgba(50, 190, 190, 0.5) url(../../assets/img/bg_@.png) no-repeat 1.87rem
        0;
      background-size: auto 100%;
      border-bottom: 1px solid #38366c;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid #38366c;
      border-radius: 0.05rem 0.05rem 0 0;
      &.ybf_fin {
        background: #a49753 url(../../assets/img/bg_g.png) no-repeat 1.87rem;
      }
      div {
        display: flex;
        align-items: center;
        font-size: 0.5rem;
        svg {
          width: 0.46rem;
          height: 0.46rem;
          margin-left: 0.1rem;
        }
      }
    }
    .main {
      min-height: 4.88rem;
      background: #1b203f;
      border: 1px solid #38366c;
      border-radius: 0 0 0.05rem 0.05rem;
      display: flex;
    }
  }
}
</style>
